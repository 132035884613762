<template>
  <div>
    <select
      ref="selectRef"
      v-bind="$attrs"
      :multiple="multiple"
      v-model="boundValue"
      :class="customClass"
      :style="dynamicSelectStyle"
    >
      <option v-if="placeholder" :class="$style.placeholder" :value="null" disabled hidden>
        {{ placeholder }}
      </option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :disabled="option.disabled"
        @click.prevent="(e) => { !option.disabled && onClickOption(option) }"
      >
        {{ option.label }}
      </option>
    </select>
    <p v-if="!!errorText" :class="$style.error_txt">{{ errorText }}</p>
  </div>
</template>

<script>
export default {
  model: {
    prop: "modelValue",
    event: "update",
  },
  props: {
    modelValue: {
      default: null
    },
    options: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    look: {
      type: String,
      default: "outline1",
      validator: (look) => [
        "outline1",
        "outline2",
        "border",
      ].includes(look)
    },
    caption: {
      type: String,
      default: "",
    },
    error: {
      type: [String, Boolean],
      default: "",
    },
    previousSpace: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  computed: {
    customClass() {
      return [
        this.$style.select,
        {
          [this.$style.multiple]: this.multiple,
          [this.$style.like_multiple]: this.dispLikeMultiple,
          [this.$style.placeholder_selected]: this.isPlaceholderSelected,
          [this.$style.error]: !!this.error,
        },
        this.$style[this.look],
      ]
    },
    boundValue: {
      get() {
        let value
        if (this.multiple) {
          value = this.modelValue instanceof Array ? this.modelValue : [this.modelValue]

        } else {
          value = this.modelValue instanceof Array ? this.modelValue[0] : this.modelValue
        }
        // console.log("select get: ", { this: this, value })
        return value
      },
      set(value) {
        if (this.multiple) {
          // ブラウザのselectは複数選択にキーボード入力（Mac:Command、win:Ctrl）しながら選択する必要がある。
          // こちらからの入力は無視する。何もしない
        } else {
          // console.log("select set: ", { this: this, value })
          this.$emit('update', value)
        }
      }
    },
    isPlaceholderSelected() {
      return !this.multiple && this.modelValue == null
    },
    errorText() {
      return typeof this.error === 'string' ? this.error : ''
    },
    /** multiple風表示 */
    dispLikeMultiple() {
      const line_num = this.$attrs['size']
      return !this.multiple && line_num && Number(line_num) > 1
    },
    dynamicSelectStyle() {
      return {
        width: this.width,
      }
    },
  },
  methods: {
    onClickOption(option) {
      if (this.multiple) { // 複数選択。クリックだけで選択に追加する
        const values = this.modelValue instanceof Array
          ? this.modelValue.concat()
          : this.modelValue == null
            ? []
            : [this.modelValue]
        const valueIndex = values.findIndex((value) => value == option.value)
        if (valueIndex < 0) {
          values.push(option.value)
        } else {
          values.splice(valueIndex, 1)
        }
        // console.log("select update multiple: ", { this: this, values })
        this.$emit('update', values)
        // this.$refs.selectRef.blur()
      } else { // 単数選択。ここには来ない
        // const value = option.value
        // console.log("select update: ", { this: this, value })
        // this.$emit('update', value)
      }
    }
  }
}
</script>

<style lang="scss" module>
$selectedGray: #F6F8F9;
$blackTrans10: #e7e7e7;

.select {
  color: $keyBlack;
  border: 2px solid $borderLiteGray;
  border-radius: 4px;
  outline: none;
  background: $keyWhite;

  option {
    color: $keyBlack;
    &:checked {
      color: $KeyDarkGreen;
      background: $backGroundGray;
    }
  }
  option[disabled] {
    color: $borderLiteGray;
  }

  &.placeholder_selected {
    color: $fontGray;
  }

  &:disabled {
    background: $blackTrans10;
  }
}
.select.error {
  border-color: $keyRed;
}
.select.outline1 {
  height: 56px;
  padding: 0 18px;
  font-size: 16px;
  line-height: 24px;
}

.select.outline2 {
  height: 40px;
  padding: 0 18px;
  font-size: 13px;
  line-height: 20px;
}

.select.border {
  height: 40px;
  padding: 0 32px 0 4px;
  font-size: 16px;
  line-height: 24px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-radius: 0;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;

  background-image: url('../../../assets/images/dropdown.png');
  background-repeat: no-repeat;
  background-size: 16px 8px;
  background-position: right 8px center;
}
.select:focus {
  border-color: $keyGreen;
}

.select.multiple, .select.like_multiple {
  padding: 10px 0;
  height: 100px;
  &[multiple]:focus option:checked, &[size]:focus option:checked {
    background: $KeyDarkGreen linear-gradient(0deg, $KeyDarkGreen, $KeyDarkGreen);
  }
  option {
    padding: 0 22px;
    margin: 2px 0;
    height: 22px;
    display: flex;
    align-items: center;

    &:checked {
      color: $keyWhite;
      background: $KeyDarkGreen;
    }
    &::selection {
      color: $keyWhite;
      background: $KeyDarkGreen;
    }

    &.placeholder {
      color: $fontGray;
      background: transparent;
      border-bottom: 1px solid $selectedGray;
    }

    &:disabled {
      background: $blackTrans10;
    }
  }
}

.error_txt {
  font-size: 13px;
  margin: 4px 0 0 0;
  color: #f00;
}
</style>
