<template>
  <section
    :class="[
      $style.article,
      { [$style.clip_on]: this.clip, [$style.pin_on]: this.pin },
    ]"
    @click="handleClick"
  >
    <!-- <img
      v-if="clip"
      :class="[$style.clip, {[$style.clip_on]: this.clip, [$style.clip_on_read]: this.read}]"
      src="@/assets/images/clip.svg"
      alt="クリップ"
    /> -->
    <img
      v-if="clip"
      :class="[
        $style.pin,
        { [$style.pin_on]: this.clip, [$style.pin_on_read]: this.read },
      ]"
      src="@/assets/images/favorite_pin_pink.svg"
      alt="ピン"
    />
    <header :class="$style.header_wrapper">
      <div :class="$style.header_row1">
        <div :class="$style.header_row2">
          <div v-show="!showPost" :class="$style.body_content_avatar">
            <router-link :to="profileUrl">
              <div :class="$style.avatar_wrapper">
                <base-avatar
                  size="60px"
                  :border-color="avatarColor"
                  :color="avatarColor"
                  :class="$style.avatar"
                  :path="avatarImage"
                />
                <div :class="$style.avatar_name">{{avatarName}}</div>
              </div>
            </router-link>
          </div>
          <div :class="$style.tag_block_time" v-show="!showPost">
            <base-tag
              v-if="displayType() == 'normal' ? type == 'post' : false"
              width="69px"
              height="37px"
              padding="0"
              bgColor="white"
              color="black"
              margin="20px"
              :bordered="true"
              fontSize="l"
              rounded="round-6px"
            >
              投稿
            </base-tag>
            <base-tag
              v-if="displayType() == 'normal' ? type == 'vote' :false"
              width="69px"
              height="37px"
              padding="0"
              bgColor="black"
              margin="20px"
              color="white"
              fontSize="l"
              rounded="round-6px"
            >
              投票
            </base-tag>
            <base-tag
              v-if="displayType() == 'normal' ?type == 'gift' :false"
              width="100px"
              height="37px"
              padding="0"
              bgColor="green"
              color="white"
              fontSize="l"
              rounded="round-6px"
            >
              メッセージ
            </base-tag>
            <base-tag
              v-if="displayType() == 'expired'"
              width="69px"
              height="37px"
              padding="0"
              bgColor=#FFF490
              color="black"
              fontSize="l"
              rounded="round-6px"
            >
              期間外
            </base-tag>
            <base-tag
              v-if="displayType() =='draft'"
              width="69px"
              height="37px"
              padding="0"
              bgColor=#D75888
              color="white"
              fontSize="l"
              rounded="round-6px"
            >
              下書
            </base-tag>
            <time :class="$style.block_time_text">{{ time }}</time>
          </div>
          <div>

          </div>
        </div>
      </div>
      <div v-show="!showPost" :style="centerText_styles">
        ブロックしたユーザの投稿です
      </div>
      <div :class="$style.header_row2">
        <div v-show="showPost" :class="$style.body_content_avatar">
          <router-link :to="profileUrl">
            <div :class="$style.avatar_wrapper">
              <base-avatar
                size="60px"
                :border-color="avatarColor"
                :color="avatarColor"
                :class="$style.avatar"
                :path="avatarImage"
              />
              <div :class="$style.avatar_name">{{avatarName}}</div>
            </div>
          </router-link>
        </div>
        <div :class="$style.tag_time" v-if="showPost">
          <base-tag
            v-if="displayType() == 'normal' ? type == 'post' : false"
            width="69px"
            height="37px"
            padding="0"
            bgColor="white"
            color="black"
            margin="20px"
            :bordered="true"
            fontSize="l"
            rounded="round-6px"
          >
            投稿
          </base-tag>
          <base-tag
          v-if="displayType() == 'normal' ? type == 'vote' :false"
            width="69px"
            height="37px"
            padding="0"
            bgColor="black"
            margin="20px"
            color="white"
            fontSize="l"
            rounded="round-6px"
          >
            投票
          </base-tag>
          <base-tag
            v-if="displayType() == 'normal' ?type == 'gift' :false"
            width="100px"
            height="37px"
            padding="0"
            bgColor="green"
            color="white"
            fontSize="l"
            rounded="round-6px"
          >
            メッセージ
          </base-tag>
          <base-tag
              v-if="displayType() == 'expired'"
              width="69px"
              height="37px"
              padding="0"
              bgColor=#FFF490
              color="black"
              fontSize="l"
              rounded="round-6px"
            >
              期間外
            </base-tag>
            <base-tag
              v-if="displayType() =='draft'"
              width="69px"
              height="37px"
              padding="0"
              bgColor=#D75888
              color="white"
              fontSize="l"
              rounded="round-6px"
            >
              下書
            </base-tag>
          <time :class="$style.time">{{ time }}</time>
        </div>
        <div :class="$style.counters" v-if="showPost">
          <!-- <div :class="[$style.browsed, {[$style.on]: read}]"> -->
          <div :class="[$style.browsed, { [$style.on]: read }]">
            <span :class="[$style.icon, $style.cursor_pointer]" @click.stop="readIconClick"
              ><base-icon size="22" name="eye"
            /></span>
            <span :class="$style.count">{{ readCount }}</span>
          </div>

          <div :class="[$style.commented, { [$style.on]: commentCount > 0 }]">
            <span :class="$style.icon" @click.stop="commentIconClick"
              ><base-icon size="22" name="comment-dots"
            /></span>
            <span :class="$style.count">{{ commentCount }}</span>
          </div>

          <div v-if="!isBeforeApproval" :class="[$style.like, { [$style.on]: goodFlg }]">
            <span :class="[$style.icon, $style.cursor_pointer]" @click.stop="handleSelect"
              ><base-icon size="22" name="heart" />
            </span>
            <span :class="$style.count">{{ goodsCount }}</span>
          </div>
        </div>
      </div>
    </header>
    <section
      :class="[$style.body_wrapper, { [$style.collapse]: this.collapse }]"
      :style="customStyle"
    >
      <div v-if="isDefaultImage && videoPath">
      </div>
      <div v-if="!isDefaultImage" :class="$style.picture">
        <img :src="bodyImage" @click="imageClick" alt="" />
      </div>
      <div v-if="!isDefaultImage && type != 'gift'"
        :class="$style.body_content_thumbnail_download" style="text-align: right;"
        @click.prevent.stop="fileDownload(path)"
      >
        <img
          :class="$style.file_icon"
          src="@/assets/images/file_download.svg"
          alt="icon"
        />
        <div :class="$style.body_content_link_text">ダウンロードする</div>
      </div>
      <div v-if=videoThumbnailPath :class="$style.video_thumbnail">
        <base-video-poster
          :source="
            videoThumbnailPath
          "
          display="inline-block"
          width="100%"
          v-on:click="$router.push('/union/course/' + getLectureId(displayVideoPath))"
        />
      </div>
      <div v-else-if="youtubeId" :class="$style.video">
        <iframe
          width="560"
          height="315"
          :src="
            convertVideoPath()
          "
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
      </div>
      <div v-else-if="movieFlg && movieUrl" :class="$style.video">
        <iframe
          width="560"
          height="315"
          :src="movieUrl"
          title="Video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
      </div>
      <div :class="$style.body_content">
        <p
          v-if="showPost"
          :class="[
            $style.body_content_text,
            { [$style.body_content_text_fix]: isHightFix },
          ]"
          v-html="$sanitize(text)"
        ></p>
        <div
          :class="$style.sso_link_wrapper"
          @click="ssoLinkClick"
          v-if="item != null && item.type == 4 && !movieFlg"
        >
          <div :class="$style.sso_link_text">{{ serviceName }}</div>
          <div :class="$style.sso_link_icon">
            <img src="@/assets/images/icon_traget_blank.png" width="20">
          </div>
        </div>
      </div>
      <div v-show="switchPost" :style="link_styles">
        投稿を表示しますか？<br />
        <a href="#" @click.prevent.stop="switchPostView()">Yes</a>
      </div>

      <div :class="$style.body">
        <slot name="body" />
      </div>
      <div
        v-if="bodyFileList && bodyFileList.length > 0"
        :class="$style.files"
      >
        <div v-for="file of pathList(bodyFileList)" :key="file.id">
          <div :class="$style.file">
            <button
              :class="$style.file_download"
              @click="fileDownload(file.path)"
            >
              <img
                :class="$style.file_icon"
                src="@/assets/images/file_download.svg"
                alt="icon"
              />
              {{ file.show_path }}
            </button>
          </div>
        </div>
        <!-- <a href="#" @click.prevent.stop="fileListDownload">ダウンロードする</a> -->
      </div>
      <div
        v-if="bodyFileList && bodyFileList.length > 0 || this.$scopedSlots['vote-result']"
        :class="$style.body_content_link_wrap">
        <div
          v-if="bodyFileList && bodyFileList.length > 0"
          :class="$style.body_content_link_download"
          @click.prevent.stop="fileListDownload">
          <div>
            <img
              :class="$style.file_icon"
              src="@/assets/images/file_download.svg"
              alt="icon"
            />
          </div>
          <div :class="$style.body_content_link_text">一括ダウンロード</div>
        </div>
        <slot name="vote-result"/>
      </div>

      <div v-if="collapse" :class="$style.collpasing">
        <div v-if="type == 'post' || type == 'gift'">
          <router-link :to="'/team/post-detail/' + teamId + '/' + chatId"
            ><base-icon name="angle-right" color="black"
          /></router-link>
        </div>
        <div v-if="type == 'vote'">
          <router-link :to="'/team/mini-vote-detail/' + teamId + '/' + chatId"
            ><base-icon name="angle-right" color="black"
          /></router-link>
        </div>
      </div>
      <base-modal
        v-if="showImage"
        type="round"
        imageEnlargementFlg
        @close="showImage = false"
      >
        <template v-slot:body>
          <div :class="$style.modal_content">
            <img :src="bodyImage" :class="$style.modal_image" alt="" />
          </div>
        </template>
      </base-modal>
    </section>
  </section>
</template>

<script>
import BaseAvatar from '../../base/BaseAvatar/BaseAvatar.vue'
import BaseIcon from '@/components/base/BaseIcon/BaseIcon'
import BaseModal from '@/components/base/BaseModal/BaseModal'
import BaseTag from '@/components/base/BaseTag/BaseTag'
import BaseVideoPoster from '@/components/base/BaseVideo/BaseVideoPoster'
import { mapGetters } from 'vuex'
import { autoLink } from '@/utils/helper.js'

export default {
  components: {
    BaseAvatar,
    BaseIcon,
    BaseModal,
    BaseTag,
    BaseVideoPoster,
  },
  name: 'AppListItemWide',
  props: {
    type: {
      type: String,
      validator: (type) =>
        !type ||
        [
          'post', // 投稿
          'vote', // ミニ投票
          'gift', // メッセージ
        ].includes(type),
      default: 'post',
    },
    avatarName: {
      type: String,
      default: 'はなちゃんぺ。',
    },
    avatarImage: {
      type: String,
      default: undefined,
    },
    avatarColor: {
      type: String,
    },
    text: {
      type: String,
      default: '',
    },
    teamName: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    pin: {
      type: Boolean,
      default: false,
    },
    clip: {
      type: Boolean,
      default: false,
    },
    read: {
      type: Boolean,
      default: false,
    },
    bodyColor: {
      type: String,
      default: '#FFFFFF',
    },
    path: {
      type: String,
      default: '',
    },
    bodyImage: {
      type: String,
      default: null,
    },
    videoPath: {
      type: String,
      default: null,
    },
    displayVideoPath: {
      type: String,
      default: null,
    },
    videoThumbnailPath: {
      type: String,
      default: null,
    },
    youtubeId: {
      type: String,
      default: null
    },
    movieUrl: {
      type: String,
      default: null,
    },
    movieFlg: {
      type: Boolean,
      default: false,
    },
    bodyFileList: {
      type: Array,
      default: null,
    },
    collapse: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    chatId: {
      type: Number,
    },
    readCount: {
      type: String,
      default: '',
    },
    commentCount: {
      type: String,
      default: '',
    },
    goodsCount: {
      type: String,
      default: '',
    },
    goodFlg: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: Number,
    },
    imageEnlargementFlg: {
      type: Boolean,
      default: false,
    },
    isHightFix: {
      type: Boolean,
      default: false,
    },
    profileUrl: {
      type: String,
      default: '/home/profile',
    },
    showPost: {
      type: Boolean,
      default: true,
    },
    switchPost: {
      type: Boolean,
      default: false,
    },
    showVideo: {
      type: String,
      default: '',
    },
    open_flg: {
      type: Number,
      default: 1,
    },
    start_datetime: {
      type: String,
      default: "",
    },
    end_datetime: {
      type: String,
      default: "",
    },
    service_link_text: {
      type: String,
      default: ""
    },
    isBeforeApproval: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      processing: false,
      showImage: false,
      link_styles: {
        color: 'gray',
        fontSize: '9pt',
        textAlign: 'center',
      },
      centerText_styles: {
        color: 'gray',
        fontSize: '9pt',
        textAlign: 'center',
      },
      comment_notice_flg: null,
      mention_notice_flg: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['getUserId']),
    customStyle() {
      return {
        'background-color': `${this.bodyColor}`,
      }
    },
    serviceName() {
      let serviceName = ''
      switch(this.item.service_id){
        case 1:
          serviceName = 'イベント予約ページ';
          break;
        case 2:
          serviceName = 'ひとログQoU';
          break;
        case 3:
          serviceName = 'ユニオンスマートフロー';
          break;
        case 6:
          serviceName = 'ファイルダウンロード';
          break;
      }
      if (this.service_link_text) serviceName = this.service_link_text;
      return serviceName
    },
    isDefaultImage() {
      return ['junion/post.jpg', 'junion/vote.jpg'].includes(this.path);
    }
  },
  methods: {
    handleClick() {
      // クリックイベントが発火するように設定
      this.$emit('click', this.item)
    },
    handleSelect() {
      this.$emit('select', this.item)
      // this.$emit('read', this.chatId, this.read)
    },
    switchPostView() {
      this.$emit('switchPostView', this.item)
    },
    readIconClick() {
      // this.$emit('read', this.chatId, this.read_flg)
      this.$emit('read', this.item)
    },
    commentIconClick() {
      this.$emit('comment', this.chatId)
    },
    imageClick() {
      this.showImage = true
    },
    ssoLinkClick() {
      this.$emit('sso-link')
    },
    convertVideoPath() {
      return "https://www.youtube.com/embed/" + this.youtubeId
    },
    fileDownload: async function (path) {
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/file/download', {
          path: path,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            let base64 = res.data.value.image
            let bin = atob(base64.replace(/^.*,/, ''))
            let buffer = new Uint8Array(bin.length)
            for (var i = 0; i < bin.length; i++) {
              buffer[i] = bin.charCodeAt(i)
            }

            let blob = new Blob([buffer.buffer])
            let a = document.createElement('a')
            a.href = window.URL.createObjectURL(blob)
            a.download = res.data.value.file_name
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    fileListDownload: async function () {
      let file_list = []
      this.bodyFileList.forEach((element) => {
        file_list.push({ path: element.path })
      })

      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/file/download/list', {
          file_list: file_list,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            res.data.value.file_list.forEach((element) => {
              let base64 = element.image
              let bin = atob(base64.replace(/^.*,/, ''))
              let buffer = new Uint8Array(bin.length)
              for (var i = 0; i < bin.length; i++) {
                buffer[i] = bin.charCodeAt(i)
              }

              let blob = new Blob([buffer.buffer])
              let a = document.createElement('a')
              a.href = window.URL.createObjectURL(blob)
              a.download = element.file_name
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
            })
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    pathList: function (path_list) {
      if (path_list) {
        path_list.forEach((element) => {
          element['show_path'] = element.path.substring(
            element.path.lastIndexOf('/') + 1
          )
        })
      } else {
        path_list = null
      }

      return path_list
    },
    getLectureId: function (video_path){
      return video_path.substring(video_path.lastIndexOf('/') + 1)
    },
    displayType: function () {
      if(!this.open_flg) {
        return 'draft'
      }
      else {
        if(this.start_datetime.length > 0 && this.end_datetime.length > 0) {
          let startTime = this.$dayjs(this.start_datetime);
          let endTime = this.$dayjs(this.end_datetime);
          if(!this.$dayjs().isBetween(startTime,endTime)) {
            return 'expired'
          }
        }
        return 'normal'
      }
    },
    autoLink,
  },
  watch: {
    goodsCount: function () {
      return this.goodsCount ? Number(this.goodsCount) : 0
    },
    commentCount: function () {
      return this.commentCount ? Number(this.commentCount) : 0
    },
    readCount: function () {
      return this.readCount ? Number(this.readCount) : 0
    },
  },
}
</script>

<style lang="scss" module>
$white: #f9f9f9;
$grey: #c4c4c4;
$red: #f5dcdc;
$yellow: #f1f5dc;
$green: #def5dc;
$blue: #dcf2f5;
$purple: #e2dcf5;
$pink: #f5dced;

.article {
  position: relative;
  // max-width: 900px;
  width: 100%;
  box-sizing: border-box;
  background: #fff;

  &.grey {
    border-color: $white;
  }
  &.grey {
    border-color: $grey;
  }
  &.red {
    border-color: $red;
  }
  &.yellow {
    border-color: $yellow;
  }
  &.green {
    border-color: $green;
  }
  &.blue {
    border-color: $blue;
  }
  &.purple {
    border-color: $purple;
  }
  &.pink {
    border-color: $pink;
  }

  &.clip_on {
    border: 2px solid $keyPink;
    position: relative;
    top: 30px;
  }

  &.pin_on {
    border: 1px solid #f5dcdc;
  }

  &.read {
    opacity: 50%;
  }
}
.clip {
  position: absolute;
  top: -16px;
  left: 4px;
  opacity: 0;
}
.clip_on {
  opacity: 1;
  &_read {
    opacity: 50%;
  }
}

.pin {
  position: absolute;
  top: -12px;
  left: 2px;
  transform: rotate(-21deg);
  opacity: 0;
}
.pin_on {
  opacity: 1;
  &_read {
    opacity: 50%;
  }
}

.header_wrapper {
  padding: 12px 10px 24px;

  .header_row1 {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .title {
      flex: 1;
      margin: 0 10px 0 0;
      // line-height: 16px;
      font-size: 25px;
      font-weight: bold;
      line-height: 1.5;
      word-wrap: break-word;
      overflow: hidden;
    }

    .menu {
      width: 36px;
      height: 36px;
      display: inline-block;
      align-items: right;
      justify-content: right;
    }
    .read {
      opacity: 50%;
    }
  }

  .header_row2 {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 0;

    .tag_time {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      row-gap: 10px;

      & > *:not(:last-child) {
        margin-right: 22px;
      }

      .time {
        font-size: 20px;
        transform-origin: 0;
        width: auto;
      }
    }
    .tag_block_time {
      display: flex;
      align-items: center;
      width: 280px;

      & > *:not(:last-child) {
        margin-left: 10px;
        margin-right: 22px;
      }
      .block_time_text {
        display: inline-block;
        font-size: 20px;
        transform-origin: 0;
        width: 200px;
        margin-right: 10px;
      }
    }

    .counters {
      display: flex;
      margin-left: auto;
      font-size: 20px;
      align-items: center;
      & > *:not(:last-child) {
        margin-right: 10px;
      }

      & > div{
        display: flex;
        align-items: center;
      }

      .icon {
        margin-right: 1px;
        color: #acacac;
      }
      .count {
        display: inline-block;
        font-size: 13px;
        color: $fontBlack;
        &_active {
          color: $keyPink;
        }
      }

      .browsed {
        &.on {
          .icon {
            color: $keyGreen;
          }
        }
      }
      .commented {
        &.on {
          .icon {
            color: $keyGreen;
          }
        }
      }
      .like {
        &.on {
          .icon {
            color: $keyPink;
          }
        }
      }
    }
    &.read {
      opacity: 50%;
    }
  }
}

.body_wrapper {
  padding: 35px 0 16px;
  width: 100%;
  margin: 0 auto;

  .picture {
    max-width: 640px;
    max-height: 480px;
    width: 100%;
    margin: 0 auto;

    img {
      display: block;
      max-width: 640px;
      max-height: 480px;
      width: auto;
      height: auto;
      object-fit: cover;
      margin: 0 auto;
    }
  }
  .video_thumbnail img{
      aspect-ratio: 16 / 9;
  }
  .video {
    text-align: center;
    position: relative;
    max-width: 640px;
    width: 100%;
    margin: 40px auto 0px;
    aspect-ratio: 16 / 9;
  }
  .video iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .body {
    padding: 0 6px;
    font-size: 10px;
    line-height: 15px;
    color: $fontBlack;
  }

  .files {
    text-align: end;
  }

  &.white {
    background-color: $white;
  }
  &.grey {
    background-color: $grey;
  }
  &.red {
    background-color: $red;
  }
  &.yellow {
    background-color: $yellow;
  }
  &.green {
    background-color: $green;
  }
  &.blue {
    background-color: $blue;
  }
  &.purple {
    background-color: $purple;
  }
  &.pink {
    background-color: $pink;
  }

  &.collapse {
    display: flex;
    box-sizing: border-box;
    padding: 10px;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 15px;
    }

    .picture {
      width: 50px;
      height: 50px;
      padding: 0;

      img {
        width: 50px;
        height: 50px;
      }
      a {
        display: none;
      }
    }
    .body {
      flex: 1;
      padding: 0;
      overflow: hidden;
      max-height: 45px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .collpasing {
      width: 20px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.file {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 4px;

  .file_download {
    width: 306px;
    border: 1px solid #707070;
    background-color: $keyWhite;
    padding: 11px 10px;
    border-radius: 3px;
    color: #000000;
    text-decoration: none;
    text-align: left;
    cursor: pointer;

    .file_icon {
      display: inline-block;
      padding-right: 6px;
      width: 20px;
      height: 25px;
      vertical-align: middle;
    }
  }

  input {
    display: none;
  }
}
.file_all {
  text-align: right;
  margin: 5px;
}

.file_all_download {
  cursor: pointer;
  color: $keyGreen;
  font-size: 12px;
}

.body_content {
  margin: 26px auto 0;
  max-width: 1062px;
  &_text {
    margin: 0 30px 16px;
    min-height: 34px;
    // font-size: 11px;
    // max-width: 343px;
    font-size: 16px;
    word-wrap: break-word;
    overflow: hidden;
    // font-weight: 600;
    &_fix {
      min-height: unset;
      height: 34px;
      word-break: break-word;
    }
    p {
      margin-block: 0 5px;
      line-height: 1.5;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &_link {
    color: $KeyDarkGreen;
    &:last-child {
      display: block;
      text-align: right;
    }
  }
  &_link {
    &_wrap {
      display: flex;
      justify-content: center;
      gap: 0 50px;
      margin: 16px 0;
      flex-wrap: wrap;
    }
    &_download {
      display: flex;
      gap: 0 10px;
      cursor: pointer;
      .file_icon {
        width: 26px;
        height: 26px;
        vertical-align: middle;
      }
    }
    &_text {
      font-size: 18px;
      color: inherit;
      text-decoration: underline;
    }
  }
  &_thumbnail_download {
    max-width: 640px;
    margin: 4px auto 0;
    display: flex;
    gap: 0 10px;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    .file_icon {
      width: 26px;
      height: 26px;
      vertical-align: middle;
    }
  }

  .choices_image_container {
    margin: 16px 0;
  }
  &_action {
    margin: 18px 0;
    text-align: center;
  }
}

.modal_content {
  margin: 16px auto;
  width: 80%;
  @include mobile() {
    width: auto;
    margin: 16px;
  }
  &_description {
    font-size: 11px;
  }
  &_name {
    font-size: 16px;
    margin-left: 16px;
  }
  h2 {
    font-size: 14px;
  }
  section:first-of-type {
    margin-bottom: 56px;
  }
}
.modal_image {
  width: 100%;
}

.sso_link_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 15px 0;
}

.sso_link_text{
  font-size: 13px;
  margin-right: 10px;
  border-bottom: solid 1px;
  font-weight: bold;
}

.sso_link_icon img{
  vertical-align: top;
  margin-top: 1px;
}


.body_content_avatar{
  max-width: 1062px;
  // margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  a{
    text-decoration: none;
    color: #262626;
  }

  .avatar_wrapper{
    display: flex;
    align-items: center;
   margin-right: 40px;
    gap: 0 10px;
    .avatar_name{
      font-weight: bold;
    }
  }
}

@include mobile_only() {
  .header_wrapper {
    .header_row1 {
      .title {
        font-size: 13px;
      }
    }
  }

  .body_wrapper {
    width: 100%;
  }

  .body_content {
    &_text {
      font-size: 11px;
      font-weight: 600;
      &_fix {
        min-height: unset;
        height: 34px;
        word-break: break-word;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }

    &_avatar{
      a{
        text-decoration: none;
        color: #262626;
      }
      .avatar_wrapper{
        gap: 0 10px;
        .avatar_name{
          font-size: 12px;
        }
      }
    }
  }

}
.cursor_pointer {
  cursor: pointer;
}
</style>
