<template>
  <div :class="$style.table_container">
    <div :class="$style.header">
      <div :class="$style.header_row">
        <div v-if="selectable" :class="$style.select">
          <base-check-box v-model="boundSelectedAll" />
        </div>
        <div
          v-for="(colDef, col) in colDefs"
          :key="colDef.key || `__unnamed_col_${col}`"
          :class="[$style.header_cell, {
            [$style.sortable]: isSortableCol(colDef),
            [$style.sorted]: isSortedCol(colDef)
          }]"
          @click="() => handleClickTh(col)"
        >
          <span>{{ colDef.label }}</span>
          <span v-if="isSortableCol(colDef)" :class="$style.sort_icon">
            {{ isSortedCol(colDef) ? (sortedAsc ? '↑' : '↓') : '↕' }}
          </span>
        </div>
      </div>
    </div>

    <div :class="[$style.body, {
          [$style[`bg_${bgColor}`]]: !!bgColor,
    }]">
      <div v-if="rowDataEmpty" :class="$style.empty_message">
        {{ message }}
      </div>
      <div
        v-else
        v-for="(rowDat, row) in pageSortedRowData"
        :key="getRowKeyValue(rowDat)"
        :class="[$style.row, {
            [$style.selected]: isSelectedRow(rowDat),
          [getStatusClass(rowDat)]: true
        }]"
      >
        <div v-if="selectable" :class="$style.select">
          <base-check-box
            :value="getRowKeyValue(rowDat)"
            v-model="boundSelected"
            :disabled="rowDat.user_id === currentUserId || disabledItems.includes('*') || disabledItems.includes(getRowKeyValue(rowDat))"
          />
        </div>
        <div
          v-for="(colDef, col) in colDefs"
          :key="colDef.key || `__unnamed_col_${col}`"
          :class="[$style.cell, {
            [$style.highlight]: colDef.highlightCondition && colDef.highlightCondition(rowDat),
              [$style.sorted]: isSortedCol(colDef),
              [$style.align_left]: getTextAlignCol(colDef) == 'left',
              [$style.align_center]: getTextAlignCol(colDef) == 'center',
              [$style.align_right]: getTextAlignCol(colDef) == 'right',
          }]"
          @click="() => handleClickTd(row, col, colDef, rowDat)"
        >
          <slot
            :name="colDef.slot ||`__unnamed_slot_${col}`"
            v-bind="{
              row, col,
              key: colDef.key,
              value: rowDat[colDef.key],
              colDef, rowDat,
              [colDef.key]: rowDat[colDef.key]
            }"
          >
            <template v-if="colDef.key === 'name'">
              <span :class="{ [$style.name_bold]: ['管理者', '共同管理者'].includes(rowDat.status) }">
                {{ rowDat[colDef.key] }}
              </span>
            </template>
            <template v-else-if="colDef.key === 'nickname'">
              <span
                :class="{
                  [$style.link_style]: colDef.textStyle === 'link' && rowDat.user_id !== currentUserId,
                }"
                @click="colDef.clickable && handleClickTd(row, col, colDef, rowDat)"
              >
                {{ rowDat[colDef.key] }}
                <span v-if="rowDat.user_id === currentUserId" :class="$style.current_user_label">
                  本人
                </span>
              </span>
            </template>
            <template v-else-if="colDef.key === 'status'">
              <img
                v-if="rowDat.in_team_black_list_flg === 1"
                src="@/assets/images/person_add_disabled_black_24dp.svg"
                alt=""
              />
              {{ rowDat[colDef.key] }}
            </template>
            <template v-else>
              {{ formatTd(rowDat[colDef.key], colDef) }}
            </template>
          </slot>
        </div>
      </div>
    </div>

    <div :class="$style.footer_header">
      <div :class="$style.header_row">
        <div v-if="selectable" :class="$style.footer_select">
          <base-check-box v-model="boundSelectedAll" />
        </div>
        <div
          v-for="(colDef, col) in colDefs"
          :key="colDef.key || `__unnamed_col_${col}`"
          :class="[$style.header_cell, {
              [$style.sortable]: isSortableCol(colDef),
            [$style.sorted]: isSortedCol(colDef)
          }]"
          @click="() => handleClickTh(col)"
        >
          {{ colDef.label }}
          <span v-if="isSortableCol(colDef)" :class="$style.sort_icon">
            {{ isSortedCol(colDef) ? (sortedAsc ? '↑' : '↓') : '↕' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCheckBox from "../../base/BaseCheckBox/BaseCheckBox"
export default {
  name: "TableList",
  components: { BaseCheckBox },
  props: {
    /**
     * 列定義配列
     * {
     *    // データキー名（列間で一意な名前であること）。
     *    // スロットを使わない場合は、行データの同名の値がこの列に並ぶ。
     *    // スロットを使う場合でも、ソート可能ならソート列の選択名として使うので必要。（行データに同名の値を持っている必要はない。）
     *    key?: string
     *    label?: string // ラベル名。最初の行に列名として表示される。
     *    sortable?: boolean // ソート可能な列ならtrue
     *    comparer?: (rowDatA, rowDatB) => number // 値が比較可能でないなら指定する
     *    // 列のv-slot名。
     *    // スロットテンプレートは以下のスロットプロパティを受け取る。
     *    // { row, col, key, value, colDef, rowDat, [key]: value }
     *    // スロットプロパティは以下のようにしてスロットテンプレートで受け取り、表示に利用できる。
     *    // <template v-slot:test1_slot="{ row, col, value }">({{ row }}, {{ col }}) = {{ value }}</template>
     *    // <template v-slot:test2_slot="cellData">({{ cellData.row }}, {{ cellData.col }}) = {{ cellData.value }}</template>
     *    slot?: string
     *    // 列ラベルのv-slot名。
     *    // 列ラベルの位置に文字列以外を表示するならlabelの代わりに指定する。
     *    // スロットテンプレートは以下のスロットプロパティを受け取る。
     *    // { col, key, colDef }
     *    label_slot?: string
     *
     *    // 以下、スロットプロパティやcomparerのために任意の定義を含めても良い。
     * }[]
     * */
    colDefs: {
      type: Array,
      default: () => []
    },
    /**
     * 行データ配列。
     * colDefsのkeyで定義した名前の値を持つオブジェクト配列。
     * colDefsで定義されないデータが含まれていてもよい。
     * */
    rowData:{
      type: Array,
      default: () => []
    },
    /**
     * 選択されたデータのキー値配列。
     * このデータが選択中の表示になる。キーはrowKeyで指定する。
     */
    selected: {
      type: Array,
      default: null
    },
    /**
     * データのキー。
     * データが選択された際にこのキーの値の配列が$emit('select', [...])される。
     * 省略されるとrowDataのインデックスが使用される。
     * */
    rowKey: {
      type: String,
      default: ""
    },
    /**
     * ソート列。
     * この列でソート中の表示になる。
     * noSortがfalseなら、この順序でデータもソートされて表示される。
     * */
    sortKey: {
      type: String,
      default: ""
    },
    /**
     * ソートの昇順、降順。
     * trueなら昇順、falseなら降順の表示になる。
     * noSortがfalseなら、この順序でデータもソートされて表示される。
     * */
    sortAsc: {
      type: Boolean,
      default: true
    },
    /**
     * データを実際にソートするかどうか。
     * ソート時に$emit('sort', { key, asc })のみを行うならtrue。
     * サーバでソート済みの部分データが渡される場合など。
     * */
    noSort: {
      type: Boolean,
      default: false
    },
    /**
     * ページ番号。
     * pageDataNumで指定された件数を１ページとして、表示されるページ数を指定する。
     * 管理上の開始ページ番号と一致させるためにpageStartを使うことができる。
     */
    page: {
      type: Number,
      default: 1
    },
    /**
     * １ページのデータ件数。
     * pageDataNumで指定された件数を１ページとして、一度に表示されるデータ数を指定する。
     * 全データ表示するなら省略するか（null, undefined, 0）か、データ件数より多い数を指定する。
     */
    pageDataNum: {
      type: Number,
      default: null
    },
    /**
     * ページ番号の開始位置。
     * pageが1から始まるなら1。
     */
    pageStart: {
      type: Number,
      default: 1
    },
    /**
     * データ取得モード
     * true: 都度取得(ページリンク押下時に対象データを取得するモード)
     * false: 全レコード取得(最初に対象データを取得し全データを保持するモード)[default]
     */
    fetchMode: {
      type: Boolean,
      required: false,
      default: false
    },
    bgColor: {
      type: String,
      default: "white",
      validator: (value) => ["white", "gray"].includes(value)
    },
    message: {
      type: String,
      default: "設定はありません",
    },
    selectable: {
      type: Boolean,
      default: true
    },
    currentUserId: {
      type: Number,
      required: true
    },
    disabledItems:{
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      sortedRowData: [],
      sortedKey: this.sortKey,
      sortedAsc: this.sortAsc
    }
  },
  mounted() {
    this.copyRowData()
    this.sortRowData()
  },
  computed: {
    boundSelected: {
      get() {
        return this.selected
      },
      set(value) {
        this.$emit('select', value)
      }
    },
    boundSelectedAll: {
      get() {
        const rowData = this.pageSortedRowData;
        const enabledRows = rowData.filter(rowDat =>
            !this.disabledItems.includes('*') &&
            !this.disabledItems.includes(this.getRowKeyValue(rowDat)) &&
            rowDat.user_id !== this.currentUserId
        );

        return enabledRows.length > 0 && enabledRows.every(rowDat =>
            this.selected.includes(this.getRowKeyValue(rowDat))
        );
      },
      set(value) {
        let selected;
        if (value) {
          const enabledRows = this.pageSortedRowData.filter(rowDat =>
              !this.disabledItems.includes('*') &&
              !this.disabledItems.includes(this.getRowKeyValue(rowDat)) &&
              rowDat.user_id !== this.currentUserId
          );

          selected = enabledRows.map(rowDat => this.getRowKeyValue(rowDat));
          selected = this.uniqueConcat(this.selected, selected);
        } else {
          const pageUnselected = this.pageSortedRowData.map(rowDat =>
            this.getRowKeyValue(rowDat)
          );
          selected = this.selected.filter(sel =>
            !pageUnselected.includes(sel)
          );
        }
        this.boundSelected = selected;
      }
    },
    pageSortedRowData() {
      if(this.fetchMode){ // 都度取得
        return this.sortedRowData;
      }
      const start = this.pageDataNum > 0 ? (this.page - this.pageStart) * this.pageDataNum : 0
      const end = start + (this.pageDataNum > 0 ? this.pageDataNum : this.sortedRowData.length)
      return this.sortedRowData.slice(start, end)
    },
    colDefsNum() {
      return this.colDefs.length
    },
    rowDataEmpty() {
      return this.rowData.length === 0
    }
  },
  methods: {
    getRowKeyValue(rowDat) {
      const rowKey = this.rowKey || "__originalIndex"
      return rowDat[rowKey]
    },
    isSelectedRow(rowDat) {
      return this.selected.includes(this.getRowKeyValue(rowDat))
    },
    isSortableCol(colDef) {
      return colDef.sortable && colDef.key
    },
    isSortedCol(colDef) {
      return this.isSortableCol(colDef) && colDef.key == this.sortedKey
    },
    getTextAlignCol(colDef) {
      if (colDef.textAlign && colDef.key) {
        return colDef.textAlign
      } else {
        return 'left'
      }
    },

    handleClickTh(col) {
      const colDef = this.colDefs[col]
      if (colDef.sortable) {
        if (this.sortedKey === colDef.key) {
          this.$emit("sort", {
            key: colDef.key,
            order: this.sortedAsc ? 'desc' : null
          })
        } else {
          this.$emit("sort", {
            key: colDef.key,
            order: 'asc'
          })
        }
      }
    },
    handleClickTd(row, col, colDef, rowDat) {
      if(colDef.clickable){
        this.$emit("cell-click", {row, col, colDef, rowDat})
      }
    },
    formatTd(value, colDef) {
      if (colDef.key === 'participation_date' && value) {
        return value.replace(/-/g, '/');
      }
      return value
    },
    copyRowData() {
      this.sortedRowData = this.rowData.map((rowDat, i) => ({...rowDat, __originalIndex: i}))
    },
    sortRowData() {
      if (this.noSort) { return }
      const colDef = this.sortedKey && this.colDefs.find((colDef) => colDef.key == this.sortedKey)
      const sortedKey = this.sortedKey || "__originalIndex"
      const comparer = colDef && colDef.comparer || ((rowDatA, rowDatB) => rowDatA[sortedKey] < rowDatB[sortedKey] ? -1 : +1)
      this.sortedRowData.sort(
        (rowDatA, rowDatB) => {
        const c = comparer(rowDatA, rowDatB)
          return this.sortedAsc ? c : -c;
        }
      )
    },
    uniqueConcat(...arrays) {
      return Array.from(new Set(arrays.flat()))
    },
    getStatusClass(rowDat) {
      const statusClasses = {
        '管理者': this.$style.status_admin,
        '共同管理者': this.$style.status_co_admin,
        '過去メンバー': this.$style.status_past
      };
      return statusClasses[rowDat.status] || '';
    },
  },
  watch: {
    rowData(/*rowData*/) {
      this.copyRowData()
      this.sortRowData()
    },
    sortKey(/*sortKey*/) {
      this.sortRowData()
    },
    sortAsc(/*sortAsc*/) {
      this.sortRowData()
    },
    colDefs() {
      this.sortedKey = this.sortKey
      this.sortedAsc = this.sortAsc
    }
  }
}
</script>
<style lang="scss" module>
$borderGray: #707070;
$borderLiteGray: #F0F0F0;

.header {
  font-size: 11px;
  font-weight: bold;
  border-bottom: 2px solid $borderGray;
}

.header_row {
  display: flex;
  align-items: center;
}

.body {
  font-size: 13px;
  background-color: $keyWhite;
}

.row {
  display: flex;
  align-items: center;
}

.cell {
  min-width: 0;
  &.highlight {
    background-color: #ffdddd;
    color: #d9534f;
    font-weight: bold;
  }
  &.sorted {
    font-weight: bold;
  }
  &.align_left { text-align: left; }
  &.align_center { text-align: center; }
  &.align_right { text-align: right; }
}

.select {
  padding: 12px;
  flex-shrink: 0;
}

.status_admin {
  background-color: #fef9e0;  /* 管理者用の背景色 */
}
.status_co_admin {
  background-color: #e4f9e9;  /* 共同管理者用の背景色 */
}
.status_past {
  background-color: #f9f9f9;  /* 過去メンバー用の背景色 */
}
.name_bold {
  font-weight: bold;
}

.current_user_label {
  color: #ff0000;
  font-size: 10px;
  margin-left: 4px;
}

.sort_icon {
  margin-left: 4px;
  font-size: 12px;
}

.link_style {
  color: #2dccd3;
  text-decoration: underline;
  cursor: pointer;
}
.footer_header {
  border-top: 2px solid $borderGray;
}

.header_row {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
}

.footer_select {
  padding: 12px;
}

.header_cell, .cell, .footer_th {
  padding: 12px;

  @include pc {
    &:nth-child(2) { width: 25%; }
    &:nth-child(3) { width: 25%; }
    &:nth-child(4) { width: 25%; }
    &:nth-child(5) { width: 25%; }
  }

  @include mobile {
    &:nth-child(2), &:nth-child(3) {
      width: 20%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &:nth-child(4), &:nth-child(5) { width: 30%; }
  }
}

.header_cell, .footer_th {
  &.sortable {
    cursor: pointer;

    .icon {
      margin-left: 2px;
      color: $borderGray;
    }
  }

  &.sorted {
    color: $keyPink;
    .icon {
      color: $keyPink;
    }
  }
}

.row, .header_row {
  display: flex;
  align-items: center;
  width: 100%;
}

.table_container {
  margin-bottom: 10px;
}
</style>
