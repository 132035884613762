<template>
  <div class="$style.open_layout">
    <app-header
      :has-login="false"
      :show-menu="false"
      :enableRedirectToTop="false"
    >
    </app-header>
    <div :class="$style.open_content">
      <div :class="$style.open_content_body">
        <div :class="$style.wrapper">
          <section :class="$style.container">
            <h1 :class="$style.head_section">
              <img :class="$style.logo" src="@/assets/images/open_logo.svg" />
            </h1>
            <p :class="$style.title_section">
              {{ getTitle }}
            </p>
            <p :class="$style.message_section">
              <span v-html="getMessage"></span>
            </p>
            <div :class="$style.maintenance_notice">
              <p :class="$style.maintenance_text">
                【メンテナンス作業予定日時】<br />2025年01月31日（金）13:00~16:00<br />メンテナンス作業が終了次第公開いたします。
              </p>
            </div>
          </section>
        </div>
      </div>
      <app-footer />
    </div>
  </div>
</template>
<script>
import AppHeader from '@/components/app/AppOpenHeader/AppOpenHeader'
import AppFooter from '@/components/app/AppFooter/AppFooter'
export default {
  components: { AppHeader, AppFooter },
  props: {},
  data: function () {
    return {}
  },
  methods: {},
  computed: {
    getTitle() {
      return 'ただいまメンテナンス中です'
    },
    getMessage() {
      return 'ただいまメンテナンスのため一時サービスを停止しております。<br>大変ご不便をおかけいたしますが、今しばらくお待ちください。'
    },
  },
}
</script>
<style lang="scss" module>
.open_content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 90px);
  background: $keyYellow;
}
.open_content_body {
  display: flex;
  flex: 1;
  background: url('~@/assets/images/open_top_bg.svg') repeat;
  background-position: -30px -150px;
  background-attachment: fixed;
}
.wrapper {
  color: $keyBlack;
  text-align: left;
  margin: 0 auto;
  box-sizing: border-box;
  width: 1080px;
  padding: 34px 82px;

  @include mobile() {
    width: 375px;
    padding: 42px 32px;
  }

  * {
    margin: 0;
    padding: 0;
  }

  .pc {
    @include mobile() {
      display: none !important;
    }
  }
  .sp {
    @include pc() {
      display: none !important;
    }
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;

    .head_section {
      display: flex;
      align-items: center;
      font-size: 29px;
      font-weight: bold;
      margin-bottom: 24px;
      flex-direction: column;

      @include pc() {
        font-size: 45px;
        margin-bottom: 35px;
      }

      .logo {
        width: 95px;
        @include mobile() {
          width: 64px;
          margin-bottom: 4px;
        }
      }
    }
    .title_section {
      font-size: 20px;
      line-height: 30px;
      font-weight: bold;
      margin-bottom: 0px;
      display: flex;
      justify-content: center;

      @include pc() {
        font-size: 30px;
        line-height: 48px;
        text-align: center;
        margin-bottom: 0px;
      }
      &.status {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
    .message_section {
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color: #707070;
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 20px auto 40px;

      @include mobile() {
        font-size: 12px;
        line-height: 24px;
        margin-bottom: 40px;
        width: 280px;
      }
      a {
        color: $keyGreen;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        text-decoration: underline;
        @include mobile() {
          font-size: 11px;
        }
      }
    }

    .maintenance_notice {
      border: 2px solid black;
      width: 468px;
      height: 120px;
      background-color: white;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      .maintenance_text {
        color: #383838;
        font-size: 13px;
        padding: 20px;
        width: 288px;
        height: 65px;
        text-align: center;
      }
    }
  }
}
</style>
