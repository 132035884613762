<template>
  <section>
    <div :class="$style.mini_vote_detail">
      <app-list-item-wide
        v-if="chat != null && dataTeam != null"
        type="vote"
        :item="chat"
        :chat-id="chat_id"
        :title="chat.title"
        :team-name="dataTeam.name"
        :show-video="null"
        :text="chat.formatted_text_web"
        :time="
          time(
            chat.vote_start_datetime,
            chat.vote_end_datetime,
            chat.start_datetime,
            chat.end_datetime,
            chat.created_at
          )
        "
        :team-id="team_id"
        :clip="!!chat.pin_flg"
        :good-flg="!!chat.good_flg"
        :profile-url="chat.profile_url"
        :read="!!chat.read_flg"
        :read-count="chat.read_count ? String(chat.read_count) : '0'"
        :comment-count="comment_count ? String(comment_count) : '0'"
        :goods-count="chat.good_count ? String(chat.good_count) : '0'"
        :body-color="
          chat.block_user_flg && !showPostView
            ? '#DDDDDD'
            : chat.background_color
            ? `rgb(${chat.background_color})`
            : '#FFFFFF'
        "
        :body-file-list="
          chat.block_user_flg && !showPostView ? null : chat.path_list
        "
        :avatar-name="chat.chat_user_nickname"
        :avatar-image="
          chat.block_user_flg
            ? require('@/assets/images/block_chat_user.png')
            : chat.created_by_url
        "
        :path="chat.block_user_flg && !showPostView ? null : chat.path"
        :body-image="
          chat.block_user_flg && !showPostView
            ? ''
            : chat.image_url
            ? chat.image_url
            : ''
        "
        :video-path="chat.display_video_path ? String(chat.display_video_path) : null"
        :display-video-path="chat.display_video_path ? String(chat.display_video_path) : null"
        :video-thumbnail-path="chat.video_thumbnail_path ? String(chat.video_thumbnail_path) : null"
        :youtube-id="chat.youtube_id ? String(chat.youtube_id) : null"
        :movie-url="movieUrl ? String(movieUrl) : null"
        :movie-flg="movieFlg ? movieFlg : null"
        :show-post="chat.block_user_flg && !showPostView ? false : true"
        :switch-post="chat.block_user_flg && !showPostView ? true : false"
        :open_flg="chat.open_flg"
        :start_datetime="chat.start_datetime"
        :end_datetime="chat.end_datetime"
        @select="clickGood"
        @read="clickRead"
        @switchPostView="switchPostView"
      >
        <template v-slot:body>
          <div :class="$style.body_content">
            <base-radio-item
              v-if="showVoteChoices"
              name="choices"
              :values="vote_choices"
              :verticallyLong="true"
              @change="changeVoteAnswer"
            />

            <div v-if="showButtonSubmit" :class="$style.body_content_action">
              <base-button
                width="185px"
                rounded="rounded"
                :disabled="is_send_disabled"
                :bg-color="is_send_disabled ? 'light-gray': 'green'"
                @click="postVoteAnswer"
                >送信</base-button>
            </div>
            <!-- <a
              v-if="showButtonResult"
              :class="$style.body_content_link"
              v-bind:href="'/team/mini-vote-result/' + team_id + '/' + chat_id"
              >投票結果を表示する</a> -->

          </div>
        </template>
        <template v-slot:vote-result>
          <div
            :class="$style.body_content_link_button"
            v-if="showButtonResult"
            @click="$router.push('/team/mini-vote-result/' + team_id + '/' + chat_id)"
            >
              <img src="@/assets/images/insert-chart.svg" alt="chart" >
              <div :class="$style.body_content_link_button_text">投票結果を表示する</div>
          </div>
        </template>>
      </app-list-item-wide>

      <div
        v-if="showCommentForm == true"
        :class="$style.mini_vote_detail_action"
      >
        <base-button
          width="185px"
          rounded="rounded"
          id="linkComments"
          @click="comment"
        >コメントする</base-button>
      </div>
      <team-chat-accordion
        :title="'コメント（' + comment_count + '）'"
        type="borderless"
        expanded
        :show-sort-label="true"
        @direction-changed="clickSortLabel"
        :order-by="direction"
      >
        <div :class="$style.post_comments">
          <div v-if="!comment_count" :class="[$style.post_comments_no_comments_yet, commentMarker]" :style="scrollMargin">コメントはありません。</div>
          <div v-for="comment of comments" :key="comment.comment_id" :class="commentMarker" :style="scrollMargin" :id="'comment-' + comment.comment_id">
            <app-comment-list-item
              :authId="juid"
              :class="$style.post_comments_item"
              :comment-id="comment.comment_id"
              :chatId="chat_id"
              :name="comment.user_name"
              :userId="comment.user_id"
              :type="comment.type"
              :body="addMentionedStyle(comment)"
              :goodCount="comment.good_count"
              :goodFlg="!!comment.good_flg"
              :editableFlg="!!comment.editable_flg"
              :deletableFlg="!!comment.deletable_flg"
              :replyCount="comment.reply_count"
              @like="addGood"
              @editComment="editComment"
              @deleteComment="deleteComment"
              :stamp-url="comment.block_user_flg ? null : comment.stamp_url"
              :dateString="comment.block_user_flg ? '' : comment.created_at"
              :path-list="comment.block_user_flg ? null : comment.path_list"
              :body-color="comment.block_user_flg ? '#DDDDDD' : '#FFFFFF'"
              :userImagePath="
                comment.block_user_flg
                  ? require('@/assets/images/block_chat_user.png')
                  : comment.user_image_path
              "
              :showFavorite="!comment.block_user_flg"
              :showComment="!comment.block_user_flg"
              :showEllipsis="
                !comment.block_user_flg &&
                $store.state.auth.user_id != comment.created_by
                  ? true
                  : false
              "
              :switchComment="!!comment.block_user_flg"
              :report_flg="comment.is_user_reported"
              :to_personal_options="to_personal_options"
              :to_personal_loading="to_personal_loading"
              :showReport="!isUsersOwnAccount(comment)"
              @report="reportUser(comment)"
              :blockUser="comment.temp_block_user_flg"
              @blockUser="blockCommentUser(comment)"
              @switchCommentView="switchCommentView(comment)"
              :spam_flg="comment.spam_flg"
              @spam="spamComment(comment)"
            />
          </div>
        </div>
        <page-nav-team-home
          v-if="pageMax > 0"
          :class="$style.page_nav_wrap"
          :page="currentPage"
          :pageMax="pageMax"
          v-on="$listeners"
          @page="pageNoClick"
        />
      </team-chat-accordion>
    </div>
    <div v-if="showCommentForm == true" :class="$style.post_comment_form">
      <div :class="$style.post_comment_form_wrap">
        <div :class="$style.post_comment_form_label" style="margin-top: 20px;">コメントする</div>
          <base-select-taggable
            name="to_personal"
            :options="to_personal_options"
            v-model="to_personal_selected"
            multiple
            :loading="to_personal_loading"
            placeholder="コメントをお知らせしたいメンバー"
            :error="isFieldErrorMsg('to_personal', errorMsgList)"
            :is_exist_choicesLabel="true"
            :close-on-select="false"
          >
          </base-select-taggable>
        <base-input-comment
          name="comment"
          :showStampIcon="true"
          stampIconName="stamp_03.svg"
          :showRecordIcon="false"
          :showFileIcon="false"
          @update="setComment"
          @text_changed="onChangeText"
          @text_length="getTextLength"
          @stamp="postStamp"
          :textareaValue="comment_text"
          :commentErrorList="commentErrorList"
          :rows="10"
        />
        <div :class="$style.title_text">{{text_length}}/5000</div>
        <div :class="$style.member_avatar_list">
          <div v-for="user in to_personal_selected" :key="user.user_id">
            <div :class="$style.member_avatar_icon">
                <base-avatar size="60px" :path="user.profile_image_path ? user.profile_image_path : NoImage" @click.native="showProfile(user)"/>
            </div>
          </div>
        </div>
        <base-modal
            v-if="showProfileModal"
            type="round"
            @close="showProfileModal = false"
          >
            <template v-slot:body>
              <div :class="$style.modal_content">
                <div :class="$style.member_avatar" >
                  <base-avatar :class="$style.member_avatar_image" size="120px" :path="dialogUser.profile_image_path ? dialogUser.profile_image_path : NoImage" />
                </div>
                <div>
                  <h1 :class="$style.member_nickname">{{ dialogUser.nickname }}</h1>
                  <div :class="$style.member_meta_wrap">
                    <dl :class="$style.member_meta">
                      <dt>所属:</dt>
                      <dd>{{ dialogTeamListValue.affiliation }}</dd>
                      <dt>ju-ID:</dt>
                      <dd>{{ dialogUser.juid }}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </template>
          </base-modal>
        <base-input-comment
          name="attached_file"
          :showStampIcon="false"
          :showRecordIcon="false"
          :showTextArea="false"
          fileIconName="upload_file.svg"
          @file_updated="onFileSubmit"
          @file_delete="onFileDelete"
          @error="onFileError"
          textareaValue="添付ファイルを追加"
          :maxFileSize="50"
          :showTrash="true"
          :files="files"
          :imageTypes="imageTypes"
        />
        <p v-if="!chat.comment_view_type" :class="$style.comment_private_notes">※本投稿へのコメントは組織管理者のみ公開されます。</p>
        <div :class="$style.post_comment_form_button">
          <div>
            <base-button
              bordered
              rounded="rounded"
              width="150px"
              @click="cancelComment"
            >キャンセル</base-button>
          </div>
          <div>
            <base-button rounded="rounded" width="150px" @click="sendPost">送信</base-button>
          </div>
        </div>
      </div>
      <div :class="[$style.nav_buttons]">
        <page-nav-post
          :class="[$style.page_nav, $style.nav_previous]"
          :isToPrevious="true"
          :teamId="this.team_id"
          :previousPost="this.previousPost"
          :previousType="this.previousType"
        />
        <page-nav-post
          :class="[$style.page_nav, $style.nav_next]"
          :isToPrevious="false"
          :teamId="this.team_id"
          :nextPost="this.nextPost"
          :nextType="this.nextType"
        />
      </div>
    </div>
    <div :class="$style.post_bg_img" :style="headerBgStyle"></div>
  </section>
</template>
<script>
import store from '@/store';
import { mapGetters, mapActions } from 'vuex'
import AppListItemWide from '@/components/app/AppListItemWide/AppListItemWide'
import AppCommentListItem from '@/components/app/AppCommentList/AppCommentListItem'
import PageNavTeamHome from '@/components/app/AppTableList/PageNavTeamHome'
import BaseButton from '@/components/base/BaseButton/BaseButton'
import BaseRadioItem from '@/components/base/BaseRadioItem/BaseRadioItem'
import BaseInputComment from "@/components/base/BaseInputComment/BaseInputComment";
import TeamChatAccordion from "@/pages/team/components/TeamChatAccordion";
import { editTeamChatFavorite, fetchTeamChatCommentList } from '@/helper/common.js'
import { autoLink, getBase64FromDataUrl } from '@/utils/helper.js'
import { isFieldErrorMsg } from "@/helper/validator.js";
import 'floating-vue/dist/style.css'
import BaseSelectTaggable from "@/components/base/BaseSelectTaggable/BaseSelectTaggable";
import BaseAvatar from "@/components/base/BaseAvatar/BaseAvatar";
import NoImage from '@/assets/images/account-circle.svg';
import BaseModal from '@/components/base/BaseModal/BaseModal'
import PageNavPost from '@/components/app/AppTableList/PageNavPost'

export default {
  name: 'TeamMiniVoteDetail',
  components: {
    AppListItemWide,
    AppCommentListItem,
    BaseButton,
    BaseRadioItem,
    PageNavTeamHome,
    BaseInputComment,
    TeamChatAccordion,
    BaseSelectTaggable,
    BaseAvatar,
    BaseModal,
    PageNavPost,
  },
  data() {
    return {
      showCommentForm: false,
      showButtonSubmit: false,
      showButtonResult: false,
      showVoteChoices: true,
      chat: {},
      chat_user_name: '',
      chat_id: '',
      team_id: '',
      vote_choices: [],
      vote_answer_list: [],
      comments: [],
      comment_count: 0,
      comment_id: '',
      comment_value: '',
      file_list: [],
      is_send_disabled: true,
      processing: false,
      showPostView: true,
      currentPage: 1,
      perPage: 20,
      direction: 'asc',
      dataTeam: null,
      comment_text: '',
      plain_text: '',
      text_length: 0,
      users: [],
      users_count: 0,
      stamp_id: null,
      files: [],
      imageTypes: [
        'image', // 登録済みの画像形式
        'image/apng', // APNG
        'image/avif', // AVIF
        'image/gif', // GIF
        'image/jpeg', // JPEG
        'image/png', // PNG
        'image/svg+xml', // SVG
        'image/webp', // WebP
      ],
      mentions: [],
      mentionTo: '',
      errorMsgList: [],
      headerHeight: '77px',
      commentMarker: 'cls_comment',
      to_personal_options: [],
      to_personal_selected: [],
      to_personal_loading: true,
      NoImage,
      showProfileModal: false,
      dialogUser: null,
      dialogTeamListValue: {},
      previousPost: null,
      nextPost: null,
      previousType: null,
      nextType: null,
      movieUrl: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    // 遷移元によってページを初期化する
    if (from && from.name) {
      if (from.name != 'Comment') {
        store.commit('team/initTeamVoteCurrentPage')
      }
    } else {
      if (from.path != '/') {
        // リロード時以外
        store.commit('team/initTeamVoteCurrentPage')
      }
    }
    next()
  },
  methods: {
    ...mapActions('team', ['commitChatId', 'commitTeamVoteCurrentPage']),
    isFieldErrorMsg,
    blockChatUser: async function (chat) {
      if (this.processing) {
        return
      }
      this.processing = true
      if (chat.block_user_flg) {
        if (!confirm('ユーザのブロックを解除しますか？')) {
          this.processing = false
          return
        }
        await this.deleteBlockUser(chat.created_by)
      } else {
        if (!confirm('ユーザをブロックしますか？')) {
          this.processing = false
          return
        }
        await this.updateBlockUser(chat.created_by)
      }
      this.processing = false
    },
    blockCommentUser: async function (comment) {
      if (this.processing) {
        return
      }
      this.processing = true
      if (comment.temp_block_user_flg) {
        if (!confirm('ユーザのブロックを解除しますか？')) {
          this.processing = false
          return
        }
        await this.deleteBlockUser(comment.created_by)
      } else {
        if (!confirm('ユーザをブロックしますか？')) {
          this.processing = false
          return
        }
        await this.updateBlockUser(comment.created_by)
      }
      this.processing = false
    },
    async updateBlockUser(block_user_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/block/registration', {
          block_user_id: block_user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.initFetch()
            alert('ユーザをブロックしました')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    async deleteBlockUser(block_user_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/block/delete', {
          block_user_id: block_user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.initFetch()
            alert('ユーザのブロックを解除しました')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    switchPostView() {
      if (this.processing) {
        return
      }
      this.showPostView = !this.showPostView
      if (this.showPostView) {
        if (this.chat.is_send_button == 1) {
          this.showButtonSubmit = true
        }
        if (this.chat.is_vote_result_button == 1) {
          this.showButtonResult = true
        }
        this.showVoteChoices = true
      } else {
        this.showButtonSubmit = false
        this.showButtonResult = false
        this.showVoteChoices = false
      }
    },
    switchCommentView(comment) {
      if (this.processing) {
        return
      }
      this.comments.find(
        (v) => v.comment_id === comment.comment_id
      ).block_user_flg = !comment.block_user_flg
    },
    reportUser: async function (chat) {
      if (this.processing) {
        return
      }
      if (chat.is_user_reported) {
        //３点リーダーを閉じる
      } else {
        if (!confirm('このユーザを悪質なユーザとして報告しますか？')) {
          return
        }
        this.processing = true
        await this.callReportUser(chat.created_by)
      }
      this.processing = false
      return
    },
    callReportUser: async function (user_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/user/report', {
          reported_user_id: user_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.initFetch()
            alert('管理者が内容を確認し、24時間以内に対応を致します。')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    spamChat: async function (chat) {
      if (this.processing) {
        return
      }
      if (chat.spam_flg) {
        //３点リーダーを閉じる
      } else {
        if (!confirm('この投稿を悪質な投稿として報告しますか？')) {
          return
        }
        this.processing = true
        await this.reportSpam(this.$route.params.chat_id) //レスポンスにない為
      }
      this.processing = false
    },
    async reportSpam(chat_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/team/chat/spam', {
          chat_id: chat_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.initFetch()
            alert('管理者が内容を確認し、24時間以内に対応を致します。')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    spamComment: async function (comment) {
      if (this.processing) {
        return
      }
      if (comment.spam_flg) {
        //３点リーダーを閉じる
      } else {
        if (!confirm('このコメントを悪質なコメントとして報告しますか？')) {
          return
        }
        this.processing = true
        await this.reportSpamComment(comment.comment_id)
      }
      this.processing = false
    },
    async reportSpamComment(comment_id) {
      await this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/team/chat/comment/spam', {
          comment_id: comment_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            //データ再取得
            this.initFetch()
            alert('管理者が内容を確認し、24時間以内に対応を致します。')
          }
        })
        .catch((err) => {
          if (err.response.status != '200') {
            alert(err.response.data.value.errors[0].message)
          }
        })
    },
    fetchUserInfo: function () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/user/info')
        .then((res) => {
          if (res.data.status == 'success') {
            this.user_id = res.data?.value?.user?.user_id
            this.nickname = res.data?.value?.user?.nickname
            this.juid = res.data?.value?.user?.juid
          } else {
            this.user_id = null
            this.nickname = null
            this.juid = null
          }
        })
        .catch((err) => {
          this.user_id = null
          this.nickname = null
          this.juid = null
          throw new Error(err)
        })
    },
    //chat情報取得
    fetchChatInfo: function () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/chat/info', {
          params: { chat_id: this.$route.params.chat_id },
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.chat = res.data.value
            this.vote_choices = res.data.value.vote_choices
            this.setVoteAnswer()
            if (this.chat.block_user_flg) {
              this.showButtonSubmit = false
              this.showButtonResult = false
              this.showVoteChoices = false
              this.showPostView = false
            } else {
              this.showPostView = true
              this.showVoteChoices = true
              if (this.chat.is_send_button == 1) {
                this.showButtonSubmit = true
              }
              if (this.chat.is_vote_result_button == 1) {
                this.showButtonResult = true
              } else {
                this.showButtonResult = false
              }
            }
            this.$emit('chat', this.chat)
          }
        })
        .catch((err) => {
          this.chat = null
          throw new Error(err)
        })
    },
    clickSortLabel: async function (direction) {
      this.direction = direction
      this.setCurrentPage(1)
      await this.fetchCommentList()
    },
    fetchCommentList: async function (offset = this.offset) {
      await this.callGetCommentList(offset)
      if (this.comment_count > 0 && this.currentPage > this.pageMax) {
        // 画面遷移している間にコメントが削除され、表示するページがなくなっていた場合、再取得
        this.setCurrentPage(this.pageMax)
        await this.callGetCommentList(this.offset)
      }

      var tempComments = []
      this.comments.forEach((elem) => {
        elem['temp_block_user_flg'] = elem.block_user_flg
        tempComments.push(elem)
      })
      this.comments = tempComments
    },
    refetchCommentList : async function () {
      this.initInput()
      // 最新のコメント（最終ページに表示）を再取得する
      if (this.comments.length == this.perPage) {
        this.setCurrentPage(this.pageMax + 1)
      } else {
        this.setCurrentPage(this.pageMax)
      }
      if (this.direction == 'desc') {
        this.setCurrentPage(1)
      }
      await this.fetchCommentList()
      this.scrollToLatestComment()
    },
    fetchTeamInfo: function () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/info', { params: { team_id: this.$route.params.team_id } })
        .then((res) => {
          this.dataTeam = res.data.value?.team
        })
    },
    callGetCommentList: async function (offset) {
      offset = Number.isNaN(offset) ? 0 : offset
      const params = {
        chat_id: this.$route.params.chat_id,
        sort: 'created_at',
        direction: this.direction,
        limit: this.perPage,
        offset: offset,
      }
      const res = await fetchTeamChatCommentList(params)

      if (res.status == 'success') {
        this.comments = res.value.comments
        this.comment_count = res.value.comment_count
      } else {
        this.comments = []
        this.comment_count = 0
      }
    },
    fetchScreenAuthComment: function () {
      return this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/screen_auth/comment/registration', {
          chat_id: this.$route.params.chat_id,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.showCommentForm = true
          }
        })
        .catch((err) => {
          if (err.response.status === 461) {
            this.showCommentForm = false
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    fetchMentionList: function () {
      return this.$axios
        .get('/api/' + this.$constants.API_VERSION + '/team/chat/mention/list', {
          params: { chat_id: this.$route.params.chat_id }
        })
        .then((res) => {
          if (res.data.status == 'success') {
            const userList = res.data.value?.mention_user_list
            if (userList) {
              userList.forEach((user) => {
                this.to_personal_options.push({
                  id: user.user_id,
                  juid: user.juid,
                  nickname: user.nickname,
                  label: user.nickname
                    ? user.nickname
                    : user.name
                    ? user.name
                    : user.juid,
                  choicesLabel: user.nickname_juid,
                  profile_image_path: user.profile_image_path
                });
              });
            }
            this.to_personal_loading = false;
            this.users = res.data.value.mention_user_list.map(user => {
              return {
                 user_id: user.user_id,
                 value: user.nickname,
              }
            })
            this.users_count = this.users.length;
          } else {
            this.users = []
          }
        })
    },
    changeVoteAnswer: function (value, checked) {
      if (this.chat.vote_type == 1) {
        // 回答の選択が単一の場合一旦選択クリア
        this.vote_choices.forEach((element) => {
          element.choice_flg = 0
        })
      }
      // 変更された選択肢の選択を反映
      this.vote_choices.forEach((element) => {
        if (element.id == value) {
          element.choice_flg = checked
        }
      })
      this.setVoteAnswer()
    },
    setVoteAnswer: function () {
      this.is_send_disabled = true
      this.vote_answer_list = []
      this.vote_choices.forEach((element) => {
        this.vote_answer_list.push({
          chat_id: this.chat_id,
          vote_choice_id: element.id,
          choice_flg: element.choice_flg,
        })
        if (element.choice_flg) {
          // １つでも選択されていた場合は送信ボタン押下できる
          this.is_send_disabled = false
        }
      })
    },
    postVoteAnswer: function () {
      this.processing = true
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/team/chat/vote_answer/registration', {
          vote_answer_list: this.vote_answer_list,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            window.location.reload()
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    comment() {
      if (this.chat.block_user_flg) {
        alert('ブロックしているのでコメントできません')
        return
      }

      this.$router.push(
        `/team/comment/${this.$route.params.team_id}/${this.$route.params.chat_id}/${this.chat.type}`
      )
    },
    setComment: function (value) {
      this.errorMsgList = []
      this.comment_text = value
    },
    onChangeText: function (text) {
      this.plain_text = text;
    },
    getTextLength: function (value) {
      this.text_length = value
    },
    setMentions() {
      this.mentions = []

      if (this.to_personal_selected.length == 0) {
        // メンション対象が選択されていない場合はreturn
        return true
      }

      // メンション対象ユーザーのuser_idの配列を作成する
      this.mentions = this.to_personal_selected.map(function(selected){
        return { user_id: selected.id }
      })

      return true
    },
    reflectData(resultObj) {
      const { type, data, errors } = resultObj
      this.errorList = errors
      switch (type) {
        case 'favorite': // お気に入りに追加/削除する
          this.chat.user_favorite_flg = data.user_favorite_flg
          break
        case 'clip': // クリップする/外す
          this.chat.pin_flg = data
          break
        case 'read': // 未読/既読にする
          this.clickRead()
          break
        case 'delete': // 削除する
          this.$router.push(`/team/home/${this.$route.params.team_id}`)
          break
        default:
          break
      }
    },
    addGood: function (comment_id, good_flg) {
      if (this.processing) return

      this.processing = true
      let param_good_flg = 1
      if (good_flg && good_flg == true) {
        param_good_flg = 0
      }
      this.$axios
        .post('/api/' + this.$constants.API_VERSION + '/team/chat/comment/user', {
          comment_id: comment_id,
          read_flg: 1,
          good_flg: param_good_flg,
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.comments.find((v) => v.comment_id === comment_id).good_flg =
              param_good_flg
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    editComment: function (comment_id) {
      this.$router.push(
        `/team/comment/${this.$route.params.team_id}/${this.$route.params.chat_id}/${this.type}/${comment_id}`
      )
    },
    deleteComment: function (comment_id) {
      this.$axios
        .delete('/api/' + this.$constants.API_VERSION + '/team/chat/comment/delete', {
          params: {
            comment_id: comment_id,
          },
        })
        .then((res) => {
          if (res.data.status == 'success') {
            this.fetchCommentList()
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    onFileSubmit([data_url, file]) {
      this.errorMsgList = []
      if (1 <= this.files.length) {
        this.errorMsgList.push({
          attribute: 'path_list',
          message: '添付ファイルは1件まで登録できます。',
        })
        return
      }

      this.files.push({
        id: '',
        type: file.type,
        name: file.name,
        url: data_url,
        is_new: true,
      })
    },
    onFileDelete(file, index) {
      file
      index
      this.errorMsgList = []
      this.files = []
    },
    onFileError(errors) {
      this.errorMsgList = []
      if (errors.filesize) {
        this.errorMsgList.push({
          attribute: 'path_list',
          message: '添付ファイルのファイルサイズの上限は50Mbyteです。',
        })
      }
    },
    sendPost() {
      if (!this.setMentions()) return
      if (this.files.length == 0) {
        this.postComment(true)
      } else {
        this.postFile()
      }
    },
    postFile: function () {
      if (this.processing) return
      this.processing = true
      this.$router.app.$emit('show-spinner', true)
      this.heavyProcess()
    },
    heavyProcess() {
      this.errorMsgList = []
      let extension = this.files[0].name.substring(
        this.files[0].name.lastIndexOf('.')
      )
      let file_type = ''
      let comment_type = null

      if (
        extension.toUpperCase().match(/\.(jpg)$/i) ||
        extension.toUpperCase().match(/\.(jpeg)$/i) ||
        extension.toUpperCase().match(/\.(png)$/i) ||
        extension.toUpperCase().match(/\.(gif)$/i)
      ) {
        file_type = 'image'
        comment_type = 2
      } else if (extension.toUpperCase().match(/\.(aac)$/i)) {
        file_type = 'audio_file'
        comment_type = 4
      } else {
        file_type = 'file'
        comment_type = 2
      }
      var convert_base64 = getBase64FromDataUrl(this.files[0].url)
      this.file_list.push({
        file_name: this.files[0].name,
        file: convert_base64,
        file_type: file_type,
      })

      this.$axios
        .post(
          '/api/' +
            this.$constants.API_VERSION +
            '/team/chat/comment/registration',
          {
            chat_id: this.chat_id,
            type: comment_type,
            file_list: this.file_list,
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            if (this.comment_text.length != 0) {
              this.postComment()
            }
            this.refetchCommentList()
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            this.errorMsgList = e.response.data.value.errors
          }
        })
        .finally(() => {
          this.processing = false
          this.$router.app.$emit('show-spinner', false)
        })
    },
    postStamp: function (args) {
      this.errorMsgList = []
      this.stamp_id = args.id
      this.$axios
        .post(
          '/api/' +
            this.$constants.API_VERSION +
            '/team/chat/comment/registration',
          {
            chat_id: this.chat_id,
            type: 3,
            stamp_id: this.stamp_id,
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.refetchCommentList()
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            this.errorMsgList = e.response.data.value.errors
          }
        })
        .finally(() => {
          this.processing = false
          this.$router.app.$emit('show-spinner', false)
        })
    },

    /**
     * メンション対象ユーザーに選択されているユーザーのニックネームを
     * mentionタグで囲んだ文字列を取得する
     */
    getSelectedUserWrapInMentionTag: function () {

      if (this.to_personal_selected.length == 0){
        return ''
      }

      let wrapped_list = this.to_personal_selected.map(function(selected){
        return `<mention juid="${selected.juid}">@${selected.nickname}</mention>`
      })

      let result = wrapped_list.join(' ')
      result = '<p>' + result + '</p>'
      return result
    },

    postComment: function (toggle_processing = false) {
      // スクロールアップする処理
      window.scrollTo(0, 0);

      this.$router.app.$emit('show-spinner', true)
      if (toggle_processing) {
        if (this.processing) return
        this.processing = true
      }

      this.errorMsgList = []
      // this.stamp_id = args.id
      let formatted_text_web = this.comment_text
      // メンション対象ユーザーにmentionタグを付ける
      let mention_string = this.getSelectedUserWrapInMentionTag()

      formatted_text_web = mention_string + formatted_text_web

      this.$axios
        .post(
          '/api/' +
            this.$constants.API_VERSION +
            '/team/chat/comment/registration',
          {
            chat_id: this.chat_id,
            type: 1,
            text: this.plain_text,
            formatted_text_web: formatted_text_web,
            mentions: JSON.parse(JSON.stringify(this.mentions)),
          }
        )
        .then((res) => {
          if (res.data.status == 'success') {
            this.refetchCommentList()
            this.to_personal_selected = []
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 400) {
            this.errorMsgList = e.response.data.value.errors
          }
        })
        .finally(() => {
          this.processing = false
          this.$router.app.$emit('show-spinner', false)
        })
    },
    cancelComment: function () {
      this.initInput()
    },
    clickGood: async function () {
      if (this.processing) return

      this.processing = true

      const good_flg = !!this.chat.good_flg
      let param_good_flg = 1
      if (good_flg) {
        param_good_flg = 0
      }

      const result = await editTeamChatFavorite({
        chat_id: this.chat_id,
        good_flg: param_good_flg,
      })
      if (result.status == 'success') {
        if (good_flg) {
          this.chat.good_count--
        } else {
          this.chat.good_count++
        }
        this.chat.good_flg = param_good_flg
      }

      this.processing = false
    },
    clickRead: async function () {
      if (this.processing) return

      this.processing = true
      // 投稿内ユーザー登録・更新 [既読可否]
      const targetFlg = this.chat.read_flg ? 0 : 1
      const { status } = await editTeamChatFavorite({
        chat_id: this.chat_id,
        read_flg: targetFlg,
      })
      if (status == 'success') {
        this.chat.read_flg = targetFlg
        if (targetFlg) {
          this.chat.read_count++
        } else {
          this.chat.read_count--
        }
      }
      this.read_info = {read_count:this.chat.read_count, target_flg: targetFlg}
      this.$emit('readClick',this.read_info)
      this.processing = false
    },
    setCurrentPage: function (page) {
      this.currentPage = page
      this.commitTeamVoteCurrentPage({page})
    },
    pageNoClick: function (page) {
      this.setCurrentPage(page)
      this.fetchCommentList()
      window.scrollTo({ top: 0, left: 0 })
    },
    async getUnionOneSpaceMovieParams(chat_id) {
      const response = await this.$axios.post(
        '/api/' + this.$constants.API_VERSION + '/auth/union-one-space/movie/params',
        { chat_id: chat_id }
      );
      if (response.data.status !== 'success') {
        alert('パラメータ取得に失敗しました');
        return null;
      }
      return response.data.value;
    },
    async getUnionOneSpaceMovieUrl(params) {
      const response = await this.$axios.post(params.api_url, {
        account_id: params.account_id,
        file_id: params.file_id,
        juid: params.juid,
        time_stamp: params.time_stamp,
        hash: params.hash,
      });
      if (response.data.status !== 200) {
        alert(`エラーコード${response.data.status}です`)
        return null;
      } else {
        return response.data.movie_url;
      }
    },
    async fetchMovieUrl(chat_id) {
      try {
        const params = await this.getUnionOneSpaceMovieParams(chat_id);
        if (!params) return;
        const movieUrl = await this.getUnionOneSpaceMovieUrl(params);
        if (movieUrl) {
          this.movieUrl = movieUrl;
        }
      } catch (error) {
        this.$logerror(error);
        alert(error);
      }
    },
    async initFetch() {
      // 表示位置調整
      let offset = this.$route.params.type ? -1 : this.offset
      const hash = window.location.hash;
      console.log('initFetch:'+hash);
      if (hash) {
        const array = hash.split('-');
        const index = array[2];
        offset = Math.floor(index / this.perPage) * this.perPage
        console.log('offset:'+offset);
        this.setCurrentPage(Math.floor(offset / this.perPage) + 1);
      }
      try {
        Promise.all([
          (this.chat_id = Number(this.$route.params.chat_id)),
          (this.team_id = Number(this.$route.params.team_id)),
          await this.fetchUserInfo(),
          await this.fetchChatInfo(),
          await this.fetchTeamInfo(),
          await this.fetchCommentList(offset),
          await this.fetchScreenAuthComment(),
          await this.fetchMentionList(),
        ])
      } catch (e) {
        // this.$router.push('/error')
        this.$loginfo(e)
      } finally {
        if (this.$route.params.type) {
          // 最新コメントを表示（末項の最下層）
          this.setCurrentPage(this.pageMax)
          this.scrollToLatestComment()
        }
      }
    },
    time: function (
      vote_start_datetime,
      vote_end_datetime,
      start_datetime,
      end_datetime,
      created_at
    ) {
      let startFormatStr = ''
      let endFormatStr = ''

      if (vote_start_datetime || vote_end_datetime) {
        if (vote_start_datetime) {
          startFormatStr =
            this.$dayjs(vote_start_datetime).format('YYYY/MM/DD HH:mm')
        } else if (created_at) {
          startFormatStr = this.$dayjs(created_at).format('YYYY/MM/DD HH:mm')
        }
        if (vote_end_datetime) {
          endFormatStr =
            this.$dayjs(vote_end_datetime).format('YYYY/MM/DD HH:mm')
        }
      } else {
        if (start_datetime) {
          startFormatStr =
            this.$dayjs(start_datetime).format('YYYY/MM/DD HH:mm')
        } else if (created_at) {
          startFormatStr = this.$dayjs(created_at).format('YYYY/MM/DD HH:mm')
        }
        if (end_datetime) {
          endFormatStr = this.$dayjs(end_datetime).format('YYYY/MM/DD HH:mm')
        }
      }

      // 表示設定
      if (startFormatStr && !endFormatStr) {
        return startFormatStr
      }
      if (startFormatStr && endFormatStr) {
        return startFormatStr + ' - ' + endFormatStr
      }
      return ''
    },
    /**
     * 自分宛のメンションの場合太字と黄色背景のタグを付与する
     */
     addMentionedStyle(body){

      if(body.mentions.length <= 0){
        // メンション対象が設定されていない場合はreturn
        return body.formatted_text_web;
      }
      // eslint-disable-next-line
      const regex = new RegExp(`(<mention juid=.*?>)(.*?)(<\/mention>)`);
      const is_exist = regex.test(body.formatted_text_web)
      if(is_exist){
        // mentionタグの改修実装後は、juid属性で比較
        const searchTerm = this.juid
        // 正規表現を使用してmentionタグにスタイリングを適用
        // eslint-disable-next-line
        const regex = new RegExp(`(<mention juid="${searchTerm}".*?>)(.*?)(<\/mention>)`, 'g');
        const styledHtml = body.formatted_text_web.replace(regex, `<span style="font-weight: bold; background: #f2c74466;">$1$2$3</span>`);
        return styledHtml
      }

      // mentionタグ実行前の対応
      for(let i = 0; i < body.mentions.length; i++){
        if(body.mentions[i].user_id === this.user_id){
          const re = new RegExp('@' + this.nickname, 'g')
          const replacedText = body.formatted_text_web
          const mentionedText = replacedText.replace(re, function(match) {
            return `<span style="font-weight: bold; background: #f2c74466;">${match}</span>`;
          })
          return mentionedText;
        }
      }

    },
    isUsersOwnAccount(obj) {
      return this.$store.state.auth.user_id == obj.created_by ? true : false
    },
    initInput() {
      this.comment_text = ""
      this.mentionTo = ""
      this.stamp_id = null
      this.files.splice(0)
      this.file_list.splice(0)
    },
    getCommentElements() {
      return document.getElementsByClassName(this.commentMarker)
    },
    scrollToLatestComment() {
      const comments = this.getCommentElements()
      if (comments) {
        const latest_comment = comments.item(comments.length - 1)
        latest_comment.scrollIntoView()
      }
    },
    focusComment() {
      const comments = this.getCommentElements()
      if (comments) {
        const top_comment = comments.item(0)
        top_comment.scrollIntoView({
          behavior: "smooth"
        })
      }
    },

    showProfile: async function(user){
      console.log('showProfile')
      console.log(user)
      this.dialogTeamListValue = await this.fetchTeamList(user.id)
      this.showProfileModal = true
      this.dialogUser = user
    },
    fetchTeamList: async function(user_id){
      let team_list_value

      let params = {
          "user_id": user_id,
          "search_type": "login",
          "sort": "name",
          "direction": "asc",
          "limit": 1000
      }

      if(this.$store.state.auth.user_id !== this.user_id){
          // IRET_JUNION_UNI_VERSE-1882
          // 他のユーザーからプロフィールを表示した場合は検索対象としないチームは表示対象外
          params.open_flg = 1;
        }

      await this.$axios.get('/api/' + this.$constants.API_VERSION + '/team/list', { params }).then((res) => {
        team_list_value = res.data.value
      })

      return team_list_value
    },
    async getPreviousAndNextPosts() {
      let params = {
        chat_id: this.chat_id,
        team_id: this.team_id,
      }
      const res = await this.$axios.get('/api/' + this.$constants.API_VERSION + '/team/chat/previous/next', { params })
      this.previousPost = res.data.value['previous_chat']
      this.nextPost = res.data.value['next_chat']
      this.previousType = res.data.value['previous_type']
      this.nextType = res.data.value['next_type']
      let data = {
        previousPost: this.previousPost,
        nextPost: this.nextPost,
        previousType: this.previousType,
        nextType: this.nextType,
      }
      this.$emit('previous-and-next', data)
    },
    autoLink,
  },
  computed: {
    ...mapGetters('team', ['getTeamVoteCurrentPage']),
    pageMax: function() {
      return Math.ceil(this.comment_count / this.perPage);
    },
    offset: function() {
      return (this.currentPage - 1) * this.perPage
    },
    headerBgStyle: {
      cache: false,
      get() {
        const teamCoverImg = this.dataTeam?.cover_image || ''
        return {
          backgroundImage: `url(${teamCoverImg})`,
        }
      },
    },
    scrollMargin: function () {
      return `scroll-margin-top: ${this.headerHeight};`
    },
    commentErrorList: function() {
      return !this.errorMsgList? [] : this.errorMsgList.filter((err)=> err.attribute != 'mentions')
    },
    movieFlg() {
      return this.chat.file_id && this.chat?.disp_ptn === '02'
    },
  },
  props: {
    headerMenuValue: String,
    resultObj: Object
  },
  watch: {
    headerMenuValue(newValue) {
      if (newValue == 'spam') {
        this.spamChat(this.chat)
      } else if (newValue == 'block') {
        this.blockChatUser(this.chat)
      } else if (newValue == 'report') {
        this.reportUser(this.chat)
      }
      this.$emit('executed')
    },
    resultObj(resultObj){
      if (resultObj){
        this.reflectData(resultObj)
      }
    }
  },
  async created() {
    this.$router.app.$emit('show-spinner', true)
    this.chat_id = Number(this.$route.params.chat_id)
    this.team_id = Number(this.$route.params.team_id)
    this.currentPage = this.getTeamVoteCurrentPage(this.chat_id) || 1
    await this.getPreviousAndNextPosts()
    this.commitChatId({chat_id: this.chat_id})
    await this.initFetch()
    if (!this.chat.read_flg) {
      await this.clickRead()
    }
    if (this.chat.file_id && this.chat.disp_ptn == '02') {
      await this.fetchMovieUrl(this.chat_id)
    }
    this.$router.app.$emit('show-spinner', false)
    this.comment_value = ''
    this.type = 2
    this.$router.app.$emit('page-updated', {
      pageTitle: this.chat.title
    })

    this.$nextTick(() => {
      const hash = window.location.hash;
      console.log(hash);
      if (hash) {
        const array = hash.split('-');
        const id = array[0] + '-' + array[1];
        const targetElement = document.querySelector(id);
        if (targetElement) {
          targetElement.scrollIntoView();
        }
      }
    })
  },
}
</script>
<style lang="scss" module>
.mini_vote_detail {
  max-width: 1280px;
  width: 93.75%;
  margin: 0 auto;
  padding: 0 0 25px 0;
  border-bottom: solid 7px $borderLightGray;
}

.post_comments {
  width: 88.888%;
  max-width: 1062px;
  margin: 0 auto;
  padding: 16px 0;
  &_no_comments_yet{
    margin: 50px 0 25px;
  }
}
.post_comments_item {
  padding: 16px 0 16px;
}

.body_content {
  padding: 6px 0;
  &_text {
    margin: 0;
  }
  &_link {
    color: $KeyDarkGreen;
    &:last-child {
      display: block;
      text-align: right;
    }
  }
  .choices_image_container {
    margin: 16px 0;
  }
  &_action {
    margin: 24px 0 16px;
    text-align: center;
  }
}
.comment_form_wrap {
  @include mobile() {
    width: 100%;
  }
  .comment_form {
    &_label {
      display: block;
      font-size: 14px;
      font-weight: bold;
      margin: 14px 0 18px 12px;
    }
  }
}

.mini_vote_detail_action {
  width: 100%;
  padding: 16px 0 50px;
  text-align: center;
  position: relative;
  top: 25px;
}

.mini_vote_detail_footer {
  margin: 24px 0;
  box-sizing: border-box;

  @include mobile() {
    background: $keyYellow;
    border-top: 1px solid $borderLiteGray;
    position: fixed;
    width: 100%;
    height: 85px;
    margin: 0;
    bottom: 0;
    left: 0;
    z-index: 12;
  }

  .confirm_button_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    & > :first-child {
      margin-right: 20px;
    }
  }
}

.page_nav_wrap {
  // padding: 25px 0;
  text-align: center;
}

.post_comments_header{
  border-bottom: solid 7px #fedddd;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;

  &_count{
    display: flex;
    align-items: center;
    .triangle {
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      border-bottom: 5px solid #000;
      transform: rotate(180deg);
    }
  }
  &_sort{
    display: flex;
    justify-content: space-between;
    gap: 0 30px;
    cursor: pointer;
    &_on{
      font-weight: bold;
    }
    &_off{
      text-decoration: underline;
      color: rgb(45, 204, 211);
    }
  }
}

.body_content_link{
  &_wrap{
    display: flex;
    justify-content: center;
    gap: 0 50px;
    margin: 25px 0 0;
    flex-wrap: wrap;
  }
  &_button{
    display: flex;
    gap: 0 10px;
    // margin-top: 10px;
    font-size: 13px;
    cursor: pointer;
    .icon{
      display: flex;
      img{
        display: block;
      }
    }
    &_text {
      font-size: 18px;
      color: inherit;
      text-decoration: underline;
    }
    // div:last-child{
    //   // border-bottom: solid 1px;
    //   a{
    //     color: inherit;
    //     // text-decoration: none;
    //   }
    // }
  }
}

.post_comment_form{
  max-width: 1000pt;
  width: 93.75%;
  margin: 25px auto 100px;

  &_wrap{
    width: 83.194%;
    max-width: 1062px;
    margin: 50px auto 0;
  }
  &_label{
    font-weight: bold;
    margin-bottom: 8px;
  }
  &_textarea{
    resize: none;
    width: 100%;
    height: 225px;
    padding: 0;
    border: 2px solid #C4C4C4;
    border-radius: 4px;
    box-sizing: border-box;
  }
  &_attached_file{
    display: flex;
    gap: 0 10px;
    margin-top: 10px;
    .icon{
      display: flex;
      img{
        display: block;
      }
    }
    div:last-child{
      border-bottom: solid 1px;
    }
  }
  &_button{
    display: flex;
    justify-content: center;
    gap: 10px 15px;
    margin-top: 25px;
    flex-wrap: wrap;
  }
}

.post_bg_img{
  background-repeat: no-repeat;
  background-size: cover;
  height: 256px;
  opacity: 0.2;
}

@include mobile_only() {
  .post_comments_header{
    font-size: 13px;
  }
  .post_comments {
    width: 100%;
  }
  .post_comments_item {
    padding: 16px 16px;
  }
}

.title_text {
  padding: 0 2px;
  text-align: right;
  font-size: small;
  color: #b7b7b7;
}

.member_nickname {
  font-size: 30px;
  color: $fontBlack;
  margin: 30px 0 4px;
  text-align: center;
  @include mobile(){
    margin: 20px 0 4px;
    font-size: 20px;
  }
}

.member_meta_wrap {
}
.member_meta {
  font-size: 13px;
  color: $fontGray;
  line-height: 24px;
  margin: 30px 32px 42px;
  @include mobile(){
    font-size: 10px;
    margin: 24px 32px;
  }

  dt {
    width: 80px;
    float: left;
  }
  dd {
    margin: 0;
    overflow: hidden;
  }
}

.modal_content {
  margin: 20px;
  width: auto;
  // display: flex;
  justify-content: center;
  align-items: center;
  @include mobile() {
    width: auto;
    margin: 20px;
  }
}

.member_avatar_image {
  margin: 0 auto;
}

.member_avatar_list {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-left: 12px;
}

.member_avatar_icon {
  cursor: pointer;
  text-align: left;
  margin: 5px;
}

.comment_private_notes {
  text-align: right;
  color: red;
}

.nav_buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: solid 7px $borderLightGray;
}

.page_nav {
  text-align: center;
  padding-bottom: 20px;
}

.nav_previous {
  margin-right: 10px;
}

.nav_next {
  margin-left: 10px;
}
</style>
