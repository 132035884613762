<template>
  <div :class="$style.app_table_list">
    <div :class="[$style.table_list_wrapper, {[$style.no_data]: rowDataEmpty}, {[$style.no_bottom]:noMarginB}]" v-show="showDisplay">
      <table-list
        :class="$style.table_list"
        :colDefs="colDefs"
        :rowData="rowData"
        :selected="selected"
        :rowKey="rowKey"
        :sortKey="sortKey"
        :sortAsc="sortAsc"
        :noSort="noSort"
        :page="page"
        :pageDataNum="pageDataNum"
        :pageStart="pageStart"
        :fetchMode="fetchMode"
        :bg-color="rowDataEmpty ? 'gray' : 'white'"
        :message="message"
        :selectable="selectable"
        :current-user-id="currentUserId"
        :disabled-items="disabledItems"
        v-on="$listeners"
        @sort="sortClick"
        @cell-click="cellClick"
      >
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
          <slot :name="name" v-bind="slotData" />
        </template>
      </table-list>
    </div>
    <div>

      <slot name="execute-button"></slot>

    </div>
    <div v-if="!rowDataEmpty" :class="$style.page_nav_wrapper" v-show="showDisplay && showPageList">
      <page-nav
        :class="$style.page_nav"
        :page="page"
        :pageMax="pageMax"
        v-on="$listeners"
        @page="pageNoClick"
      />
    </div>
  </div>
</template>
<script>
import TableList from "./TableList"
import PageNav from "./PageNav"

export default {
  components: { TableList, PageNav },
  props: {
    /** 列定義配列。詳細はTableListを参照 */
    colDefs: {
      type: Array,
      default: () => []
    },
    /** 行データ配列。詳細はTableListを参照 */
    rowData:{
      type: Array,
      default: () => []
    },
    /** 選択されたデータのキー値配列。詳細はTableListを参照 */
    selected: {
      type: Array,
      default: () =>[]
    },
    /** データのキー。詳細はTableListを参照 */
    rowKey: {
      type: String
    },
    /**ソート列。詳細はTableListを参照 */
    sortKey: {
      type: String,
      default: ""
    },
    /** ソートの昇順、降順。詳細はTableListを参照 */
    sortAsc: {
      type: Boolean,
      default: true
    },
    /** データを実際にソートするかどうか。詳細はTableListを参照 */
    noSort: {
      type: Boolean,
      default: false
    },
    /** ページ番号。詳細はTableListを参照 */
    page: {
      type: Number,
      default: 1,
    },
    /** １ページのデータ件数。詳細はTableListを参照 */
    pageDataNum: {
      type: Number,
      default: 10
    },
    /** ページ番号の開始位置。詳細はTableListを参照 */
    pageStart: {
      type: Number,
      default: 1,
    },
    /** ページリストの表示数。詳細はPageNavを参照 */
    pageListNum: {
      type: Number,
      default: 6
    },
    showPageList: {
      type: Boolean,
      default: true
    },
    /** 全データ件数。 */
    totalRecord: {
      type: Number,
      required: false,
      default: 0
    },
    /** データ取得モード。 */
    fetchMode:{
      type: Boolean,
      required: false,
      default: false,
    },
    /* margin no bottom  */
    noMarginB:{
      type: Boolean,
      required: false,
      default: false,
    },
    message: {
      type: String,
      default: null,
    },
    selectable: {
      type: Boolean,
      default: true
    },
    currentUserId: {
      type: Number,
      required: true
    },
    disabledItems:{
      type: Array,
      default: () => []
    },
  },
  computed: {
    pageMax() {
      if(this.fetchMode){ // API都度取得モード
        return this.pageDataNum > 0 ? Math.ceil(this.totalRecord/ this.pageDataNum) : 1
      }
      return this.pageDataNum > 0 ? Math.ceil(this.rowData.length / this.pageDataNum) : 1
    },
    showDisplay() {
      if(this.fetchMode){ // 都度取得
        if(this.rowData && this.rowData.length == 0){
          return false;
        }
      }
      return true;
    },
    rowDataEmpty() {
      return this.rowData.length === 0
    }
  },
  methods: {
    pageNoClick: function (listPage) {
      this.$emit('page-no-click', listPage)
    },
    sortClick: function(sortObj) {
      this.$emit('sort-click', sortObj)
    },
    cellClick: function(cellObj) {
      this.$emit('cell-click', cellObj)
    }
  }
}
</script>
<style lang="scss" module>
.app_table_list {
  .table_list_wrapper {
    margin-bottom: 10px;
    &.no_bottom {
      margin-bottom: 20px;
    }
    &.no_data {
      margin-bottom: 0;
    }

    .table_list {
      width: 100%;
    }
  }

  .page_nav_wrapper {
    text-align: center;

    .page_nav {}
  }
}

.button_wrapper {
  margin: 10px 0;
  text-align: center;
}
</style>
